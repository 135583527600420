import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, DashboardDto, ModuleKeywords } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-dashboard-item-form',
  templateUrl: './dashboard-item-form.component.html',
  styleUrl: './dashboard-item-form.component.scss',
})
export class DashboardItemFormComponent extends BaseForm<DashboardDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.Dashboard);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat({ ...this.formGroup.getRawValue() })
      : this.getChangedFormValues({ ...this.formGroup.getRawValue() }).updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }
  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      description: new FormControl(null),
      icon: new FormControl(null),
      color: new FormControl(null),
      defaultColorPalette: new FormControl(null),
    });
  }
}
